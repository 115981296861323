.shape {
  fill-opacity: 0.3;
}
.shape:hover {
  fill-opacity: 0.65;
  z-index: 100;
}
.scale {
  fill: #fafafa;
  stroke: #999;
  stroke-width: 0.2;
}
.axis {
  stroke: #555;
  stroke-width: 0.2;
}
.dot {
  fill: #fff;
  stroke: #e7e8e7;
  r: 5;
  -webkit-transition: r 0.2s;
  -o-transition: r 0.2s;
  transition: r 0.2s;
}
.dot:hover {
  stroke: #bbb;
  r: 8;
}
.caption {
  fill: #444;
  font-weight: 400;
  text-shadow: 1px 1px 0 #fff;
}
svg {
  overflow: initial;
}
/*# sourceMappingURL=index.css.map*/
